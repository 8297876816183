<template>
  <ConfigurationEditor :id='$route.query.id'/>
</template>
<script>
import ConfigurationEditor from "@/components/ConfigurationEditor";
export default {
  name: "EditV2",
  components: {ConfigurationEditor},
  created() {
    let style = document.createElement('style')
    style.innerText = '#app {min-width: unset !important;}'
    document.head.appendChild(style)
    this.styleEl = style
  },
  
  beforeDestroy() {
    document.head.removeChild(this.styleEl)
  }
}
</script>